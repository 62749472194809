/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function listRoutesToPDF() {
    var doc = new jsPDF();

    // All units are in the set measurement for the document
    // This can be changed to "pt" (points), "mm" (Default), "cm", "in"
    doc.fromHTML($('.table-list-routes').get(0), 15, 15, {
      'width': 170, 
    });
  };

  // Normalize Carousel Heights - pass in Bootstrap Carousel items.
  $.fn.carouselHeights = function() {

    var items = $(this), //grab all slides
        heights = [], //create empty array to store height values
        tallest; //create variable to make note of the tallest slide

    var normalizeHeights = function() {

        items.each(function() { //add heights to array
            heights.push($(this).height()); 
        });
        tallest = Math.max.apply(null, heights); //cache largest value
        items.each(function() {
            $(this).css('min-height',tallest + 'px');
        });
    };

    normalizeHeights();

    $(window).on('resize orientationchange', function () {
        //reset vars
        tallest = 0;
        heights.length = 0;

        items.each(function() {
            $(this).css('min-height','0'); //reset min-height
        }); 
        normalizeHeights(); //run it again 
    });
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

(function($) {

  // Recupera il valore del parametro passato tramite l'url
  function getlinkParameter(link, sParam) {
    var sPageURL = decodeURIComponent(link.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
  };

  // Setta i parametri passati tramite l'url nei .data del pulsante di conferma
  function setConfirmData(link) {
    $('#modalConfirmOK').data('action', getlinkParameter(link.attr('href'), 'action') );
    $('#modalConfirmOK').data('id', getlinkParameter(link.attr('href'), 'id') );
  }

  // Al click su un'azione che richiede la conferma, setta i data e mostra la modal
  $(document).on('click', '.requestConfirm', function(e){
    e.preventDefault();
    setConfirmData($(this));
    $('#confirmModal').modal('show');
  });

  // Al click sul link di conferma setto il Flag a true
  $('#modalConfirmOK').click( function() {
    $(this).data('confirmFlag', true);
  });

  /*
   * Alla chiusura della modal di conferma controllo se il flag di conferma e' true.
   * In caso sia true chiamo la funzione per le chiamate Ajax
   * In chiusura cancello tutti i dati settati con .data
   */ 
  $('#confirmModal').on('hidden.bs.modal', function () {
    confirmlink = $('#modalConfirmOK');
    if (confirmlink.data('confirmFlag')) {
      clv_adminUser(confirmlink.data('action'), confirmlink.data('id'));
    }
    confirmlink.removeData();
  });

})(jQuery);

jQuery( document ).ready(function( $ ) {
  $('#visible-form').click(function() {
      $('#hidden-form').collapse('show');
    });

  // Visualizzo il bottone di chiusura quando e' visibile l'hidden form
  $('#hidden-form').on('shown.bs.collapse', function () {
    $('.close[data-target="#hidden-form"]').show();
  });

  // Nascondo il bottone di chiusura quando non è visibile l'hidden form
  $('#hidden-form').on('hidden.bs.collapse', function () {
    $('.close[data-target="#hidden-form"]').hide();
  });

  var buytTicketFlag = navbarFlag = 0; 
  $('#buy-ticket').on('show.bs.collapse', function () {
    buytTicketFlag = 1;
    if (navbarFlag) $('#navbar').collapse('hide');
  });

  $('#buy-ticket').on('hide.bs.collapse', function () {
    buytTicketFlag = 0;
  });
    
  $('#navbar').on('show.bs.collapse', function () {
    navbarFlag = 1;
    if (buytTicketFlag) $('#buy-ticket').collapse('hide');
  });

  $('#navbar').on('hide.bs.collapse', function () {
    navbarFlag = 0;
  });

  $("#lineSelect").change(function(e){
    var collapseID = jQuery(this).val();
    var chSeason = jQuery('input[name=chSeason]:checked').val();

    changeCollapse(collapseID, chSeason);
  });

  $("input[name=chSeason]").change(function(e){
    var collapseID = jQuery('#lineSelect').val();
    var chSeason = jQuery('input[name=chSeason]:checked').val();

    changeCollapse(collapseID, chSeason);
  });

  $("#itinerarySelect").change(function(e){
    var collapseID = jQuery(this).val();

    changeCollapse(collapseID);
  });

  function changeCollapse(id, chSeason) {
    chSeason = chSeason || '';
    jQuery('.city-lines.in').collapse('hide');
    if (!(id == '')) {
      jQuery('#'+id+chSeason).collapse('show');
    }
  }

  $('#user_details').on('change', function() {
    var selectedOption = $(this).find(":selected").val();
    $('.user-details-accordion').each(function() {
      if ( $(this).attr('id') == ('user-details-'+ selectedOption) ){
        $(this).collapse('show');
      } else {
        $(this).collapse('hide');
      }
    });
  });
  
  $('#user-list').bootstrapTable();
  $('#preset-list').tableDnD({
    onDrop: function(table, row) {
      $('#preset-order-update').removeClass("disabled");
      var i = 1;
      var rows = table.tBodies[0].rows;
      for (var i=0; i<rows.length; i++) {
        presetArray[i] = $('#'+rows[i].id).data('preset-id');
      }
      // var presetArray = table.each('tr').data('preset-id');
      console.log(presetArray);
    }});

  $('button.load-preset').on('click', function() {
    var selectedPreset = $(this).closest('tr').data('searchticket-form');
    $( "[name='start']" ).val(selectedPreset.start);
    $( "[name='end']" ).val(selectedPreset.end);
    
    $( "[name='ar'][value="+ selectedPreset.ar +"]" ).attr('checked', true);
    $( "[name='qt']" ).val(selectedPreset.qt);
    
    var now = new Date();
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    var today = (day)+"/"+(month)+"/"+now.getFullYear();
    
    if (selectedPreset.ar) {
      jQuery("input[name=dataRitorno]").show();
      $( "[name='dataAndata'], [name='dataRitorno']" ).val(today);
    } else {
      jQuery("input[name=dataRitorno]").hide();
      $( "[name='dataAndata']" ).val(today);
    }
    
  });

  $('.submitToAction').on('click', function(e){
    //alert('prova');
    e.preventDefault();
    var url= $(this).attr('formaction');
    var form = $(this).closest('form');
    var attrName = $(this).attr('name');
    var buttonValue = $(this).val();
    input = $("<input>").attr("type", "hidden").attr("name", attrName ).val(buttonValue);
    // alert (input);
    form.append($(input));
    form.attr('action', url).submit();
  });

  $('#preset-save').on('click', function(e){
    //alert('prova');
    e.preventDefault();
    $('.ricerca form.searchticket-fom').validate({
       ignore: "[name=dataAndata]"
    });
    var url= $(this).attr('formaction');
    var form = $(this).closest('form');
    var attrName = $(this).attr('name');
    var buttonValue = $(this).val();
    input = $("<input>").attr("type", "hidden").attr("name", attrName ).val(buttonValue);
    // alert (input);
    form.append($(input));
    form.attr('action', url).submit();
  });

  $('#jump-conferma').on('click', function(e){
    //alert('prova');
    e.preventDefault();
    var url= $(this).attr('formaction');
    var form = $(this).closest('form');
    var attrName = $(this).attr('name');
    var buttonValue = $(this).val();
    input = $("<input>").attr("type", "hidden").attr("name", attrName ).val(buttonValue);
    inputBA = $("<input>").attr("type", "hidden").attr("name", 'ba' ).val('ticked');
    form.append($(input));
    form.append($(inputBA));
    $("[name=step]").val('4');
    form.attr('action', url).submit();
  });

  $('#gbo-booking-details [name=primiposti_a], #gbo-booking-details [name="primiposti_r"], #gbo-booking-details [name="sms"]').on('change', function(){
    
    var form = $(this).closest('form');
    var costoprimiposti = form.data('costoprimiposti');
    var costosms = form.data('costosms');
    var costocorse = form.data('costocorse');

    var primiposti_r = $('#gbo-booking-details [name="primiposti_r"]').val(); 
    var primiposti_a = $('#gbo-booking-details [name="primiposti_a"]').val();
    var sms = $('#gbo-booking-details [name="sms"]').is(':checked');

    if (primiposti_a=='' || primiposti_a === undefined) primiposti_a = 0;
    if (primiposti_r=='' || primiposti_r === undefined) primiposti_r = 0;
    if (sms) {sms = 1} else {sms = 0};
    
    var price = parseFloat(costocorse) + ( (parseInt(primiposti_a) + parseInt(primiposti_r)) * parseFloat(costoprimiposti)) + ( parseInt(sms) * parseFloat(costosms));

    $('#prezzo-calcolato').html(price.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }));
  });

  function dump(obj) {
     var out = '';
     for (var i in obj) {
     out += i + ": " + obj[i] + "\n";
     }
    console.log(out);
  }
});

